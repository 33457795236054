<template>
  <v-list-item dense>
    <v-list-item-avatar tile>
      <planete-icon width="24" height="24">{{ category.icon }}</planete-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ $t("notifications." + category.slug + ".subtitle") }}
      </v-list-item-title>
      <v-list-item-subtitle v-html="notification.data">
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "PoseSdp",
  props: {
    notification: Object,
    category: Object
  }
}
</script>

<style scoped>

</style>